import React from 'react';
import {cn} from '@bem-react/classname';

import Title from './-Title';
import Item from './-Item';
import Container from './-Container';
import './style.scss';

export const channels = cn('Channels');

const CHANNELS = [
  // {
  //   title:'RPL',
  //   image:'/img/channels/channel_7.png',
  //   link: 'https://www.twitch.tv/cp_rpl'
  // },
  // {
  //   title:'Euroleague basketball',
  //   image:'/img/channels/channel_1.png',
  //   link: 'https://www.twitch.tv/cp_euroleague'
  // },
  // {
  //   title:'NHL',
  //   image:'/img/channels/channel_2.png',
  //   link: 'https://www.twitch.tv/cp_nhl'
  // },
  {
    title:'FIFA 17',
    image:'/img/channels/channel_3.png',
    link: 'https://www.twitch.tv/cp_fifa'
  },
  {
    title:'FIFA classic',
    image:'/img/channels/channel_4.png',
    link: 'https://www.twitch.tv/cp_fifa_classic'
  },
  {
    title:'EPL',
    image:'/img/channels/channel_5.png',
    link: 'https://www.twitch.tv/cp_epl'
  },
  // {
  //   title:'NBA',
  //   image:'/img/channels/channel_6.png',
  //   link: 'https://www.twitch.tv/cp_nba'
  // }
];

export default () => (
  <div className={channels()}>
    <Title title='Twich каналы'/>
    <Container>
      {
        CHANNELS.map( channel => <Item image={channel.image} text={channel.title} key={channel.title} link={channel.link}/>)
      }
    </Container>
  </div>
);