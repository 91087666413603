import React, {Component} from 'react';
import {header} from "../index";

import Close from '../-Close';
import './style.scss';

class LeftMenu extends Component {
  componentDidMount(){
    document.addEventListener('click', this.closeMenu)
 }

 componentWillUnmount() {
    document.removeEventListener('click', this.closeMenu)
 }

  closeMenu = (e) => {
    if (!e.target.className.includes(header())) {
      this.props.close();
    }
 };


  render() {
    const {items, close} = this.props;

    return (
      <div className={header('LeftMenu')}>
        <Close close={close}/>
        {items}
      </div>
    )
  }
}


export default LeftMenu;