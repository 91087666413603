import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {selectSport} from "../../../redux/actions/actions";
import {header} from '../index';
import './style.scss';



const Item = ({selectedSport, path, title, tag, selectSport}) => {
  const active = selectedSport === tag;

  return (
    <div className={header('Item', {active})} onClick={() => selectSport(tag)}>
      <NavLink to={path}>{title}</NavLink>
      {active && <div className={header('ActiveItem',{sport: selectedSport})}/>}
    </div>
  )
};

const mapStateToProps  = state => ({
  selectedSport: state.selectedSport
});

const mapDispatchToProps = {
  selectSport
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
