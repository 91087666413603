import {
  GET_RESULTS,
  SHOW_RESULTS_LOADER,
  HIDE_RESULTS_LOADER,
  GET_LIVE,
  ADD_TABS, SHOW_LIVE_LOADER, HIDE_LIVE_LOADER,
  ADD_TRANSLATIONS, SELECT_LEAGUE,
  GET_FUTURE_EVENTS,
  SELECT_SPORT, SELECT_TRANSLATION, CLEAR_STATE
} from "../types";

const INITIAL_STATE = {
  selectedSport: null,
  tabs: [],
  translations: [],
  results: [],
  futureEvents: [],
  selectedTranslation: null,
  selectedLeague: null,
  liveLoading: false,
  resultsLoading: false,
  announceLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_STATE:
      return {...INITIAL_STATE};
    case SELECT_TRANSLATION:
      return {...state, selectedTranslation: {...action.payload}};
    case SELECT_SPORT:
      return {...state, selectedSport: action.payload};
    case SELECT_LEAGUE:
      return {...state, selectedLeague: action.payload};
    case ADD_TABS:
      return {...state, tabs: [...action.payload]};
    case ADD_TRANSLATIONS:
      return {...state, translations: [...action.payload]};
    case GET_LIVE:
      return {...state, live: action.payload};
    case GET_RESULTS:
      return {...state, results: [...action.payload]};
    case GET_FUTURE_EVENTS:
      return {...state, futureEvents: [...action.payload]};
    case SHOW_RESULTS_LOADER:
      return {...state, resultsLoading: true};
    case HIDE_RESULTS_LOADER:
      return {...state, resultsLoading: false};
    case SHOW_LIVE_LOADER:
      return {...state, liveLoading: true};
    case HIDE_LIVE_LOADER:
      return {...state, liveLoading: false};
    default:
      return state;
  }
};