import React, {Component} from 'react';
import {cn} from '@bem-react/classname';
import {connect} from 'react-redux';
import {getSportResults, updateResults} from '../../redux/actions/actions'
import Title from './-Title';
import Item from './-Item';
import NoEvents from './-NoEvents';
import ShowMore from './-ShowMore';
import Datepicker from './-Datepicker';
import './style.scss';

export const list = cn('List');

class List extends Component {
  state = {
    startDate: new Date(),
    timer: null
  };

  componentDidMount() {
      const formattedDate = new Date(this.state.startDate);
      const [year, month, day] = [formattedDate.getFullYear(), formattedDate.getMonth() + 1, formattedDate.getDate()];
      const timerId = setInterval(() => this.props.updateResults(this.props.selectedSport, `${year}-${month}-${day}`), 60*2*1000);
      this.setState({timer: timerId});
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  handleChange = date => {
    clearInterval(this.state.timer);
    const formattedDate = new Date(date);
    const [year, month, day] = [formattedDate.getFullYear(), formattedDate.getMonth() + 1, formattedDate.getDate()];
    this.props.getSportResults(this.props.selectedSport, `${year}-${month}-${day}`);
    const timerId = setInterval(() => this.props.updateResults(this.props.selectedSport, `${year}-${month}-${day}`), 60*2*1000);
    this.setState({
      startDate: date,
      timer: timerId
    });
  };

  render() {
    const {
      title,
      items = [],
      live,
      maxLength = 5,
      showMore,
      hideItems,
      handlerFunction,
      selected,
      defaultLength,
      date
    } = this.props;

    return (
      <div className={list()}>
        <Title title={title}/>
        {
          date && <Datepicker  selected={this.state.startDate} changeFunction={this.handleChange}/>
        }
        {
          items.length ? items.map((item, index) =>
              index < maxLength ?
                <Item
                  date={item.date}
                  text={item.name}
                  results={item.score}
                  key={item.id}
                  live={live}
                  handlerFunction={() => handlerFunction(item)}
                  selected={selected && selected.id === item.id}
                />
                :
                null)
            :
            <NoEvents text='Нет событий'/>
        }
        {(items.length && (items.length > maxLength) && showMore) ? <ShowMore handlerFunction={showMore}/> : null}
        {(items.length && (items.length < maxLength && items.length > defaultLength ) && hideItems) ? <ShowMore handlerFunction={hideItems} hide/> : null}
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  selectedSport: state.selectedSport
});

const mapDispatchToProps = {
  getSportResults,
  updateResults
};

export default connect(mapStateToProps, mapDispatchToProps)(List);