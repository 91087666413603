import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectLeague, selectTranslation} from "../../../redux/actions/actions";
import {page} from '../index';
import Tab from '../-Tab';
import Arrow from '../-Arrow'
import './style.scss';


class Tabs extends Component {
  selectTurn = (direction) => {
    let currentIndex = null;
    const {tabs, selectedLeague, selectLeague, translations } = this.props;
    tabs.forEach((item, index) => item.id === selectedLeague ? currentIndex = index : null);
    if (direction === 'right') {
      const rightItem = tabs[currentIndex+1];
      if (rightItem) {
        selectLeague(rightItem.id, translations);
      } else {
        selectLeague(tabs[0].id, translations);
      }
    } else {
      const leftItem = tabs[currentIndex-1];
      if (leftItem) {
        selectLeague(leftItem.id, translations);
      } else {
        selectLeague(tabs[tabs.length - 1].id, translations);

      }
    }
  };

  render() {
    const {tabs, selectedLeague, selectLeague, translations } = this.props;

    return (
      <div className={page('Tabs')}>
        {tabs.length && <Arrow turn='left' select={() => this.selectTurn('left')}/>}
        {
          tabs.map((item) =>
            <Tab title={item.name}
                 selected={item.id === selectedLeague}
                 select={() => selectLeague(item.id, translations)}
                 key={item.id}
            />)
        }
        {tabs.length && <Arrow turn='right' select={() => this.selectTurn('right')}/>}
      </div>
    )
  }
}


const mapStateToProps = state => ({
  tabs: state.tabs,
  translations: state.translations,
  selectedLeague: state.selectedLeague
});

const mapDispatchToProps = {selectLeague, selectTranslation};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);

