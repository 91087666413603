import React from 'react';
import {cn} from '@bem-react/classname';
import {connect} from 'react-redux';
import Title from './-Title';
import Tabs from './-Tabs';
import Content from './-Content';
import Block from '../Block';
import Results from './-Results';
import FutureEvents from './-FutureEvents';
import Live from './-Live';
import LoadingSpinner from '../LoadingSpinner';
import Video from './-Video';
import NoEvents from './-NoEvents';
import './style.scss';

export const page = cn('Page');

const Page =  ({title, loading, translations}) => {
  return (
    <div className={page()}>
      <Title title={title}/>
      {
        loading ? <LoadingSpinner/> :
          <>
            <Tabs/>
            <Content>
              <Block color='white'>
                {
                  translations.length ?
                    <>
                      <Live/>
                      <Video/>
                    </>
                    :
                    <NoEvents/>
                }
              </Block>
              <Block color='gray' last>
                <Results/>
                <FutureEvents/>
              </Block>
            </Content>
          </>
      }
    </div>

  )
};


const mapStateToProps = state => ({
  translations: state.translations,
});

export default connect(mapStateToProps, null)(Page);


