import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {list} from '../index';
import './style.scss';

export default ({selected, changeFunction}) => {
  // const dateOffset = (24*60*60*1000) * 14; //14 days
  // const minDate = new Date().setTime(new Date().getTime() - dateOffset);

  return (
    <div className={list('Datepicker')}>
      <DatePicker
        selected={selected}
        onChange={changeFunction}
        maxDate={new Date()}
        // minDate={minDate}
        popperClassName="popper"
        dateFormat='dd/MM/yyyy'
      />
    </div>
  )
}