import React from 'react';
import {cn} from "@bem-react/classname";

import Container from './-Container';
import Icon from './-Icon';
import Copyright from './-Copyright';
import Rules from './-Rules';
import './style.scss';

export const footer = cn('Footer');

export default () => {
  return (
    <div className={footer()}>
      <Container>
        <Icon/>
        <Rules/>
        <Copyright/>
      </Container>
    </div>
  )
}