import React, {Component} from 'react';
import {cn} from "@bem-react/classname";

import Background from './-Background';
import Close from './-Close';
import './style.scss';

export const modal = cn('Modal');

export default class Modal extends Component {

  componentDidMount() {
    document.addEventListener('click', this.closeOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeOutside)
  }

  closeOutside = e => {
   const {close} = this.props;
   if (e.target.className === modal('Background')) {
     close();
   }
  };

  render() {

    const {show, children, close} = this.props;

    if (!show) return null;

    return (
      <>
        <div className={modal()}>
          <Close close={close}/>
          {children}
        </div>
        <Background/>
      </>
    )
  }
}