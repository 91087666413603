import React, {Component} from 'react';
import {cn} from "@bem-react/classname";
import {connect} from 'react-redux';
import {selectSport} from "../../redux/actions/actions";


import Title from './-Title';
import Content from './-Content';
import Subtitle from './-Subtitle';
import Items from './-Items';
import './style.scss';

const FOOTBALL = [
  '1. Для организации симуляции используется лицензионная игра FIFA 2017.',
  '2. Матчи проводятся в режиме “Компьютер - Компьютер”.',
  '3. Настройки игры - стандартные, за исключением:',
  '3.1. Продолжительность тайма - 6 минут.',
  '3.2. Сложность - новичок.',
  '4. В матчах используются команды участвующие в розыгрыше Лиги чемпионов указанного сезона.',
  '5. В случае технических проблем с проведением - матч считается несостоявшимся либо прерванным в случае возникновения проблемы по ходу игры.'
];

const FOOTBALL_CLASSIC = [
  '1. Для организации симуляции используется лицензионная игра FIFA 2017.',
  '2. Матчи проводятся в режиме “Компьютер - Компьютер”.',
  '3. Настройки игры - стандартные, за исключением:',
  '3.1. Продолжительность тайма - 6 минут.',
  '3.2. Сложность - новичок.',
  '4. В матчах используются как команды национальных сборных так и клубные.',
  '5. В случае технических проблем с проведением - матч считается прерванным.'
];

const FOOTBALL_EPL = [
  '1. Для организации симуляции используется лицензионная игра FIFA 2017.',
  '2. Матчи проводятся в режиме “Компьютер - Компьютер”.',
  '3. Настройки игры - стандартные, за исключением:',
  '3.1. Продолжительность тайма - 6 минут.',
  '3.2. Сложность - новичок.',
  '4. В матчах используются команды английской премьер лиги.',
  '5. В случае технических проблем с проведением - матч считается прерванным.'
];

// const BASKETBALL = [
//   '1. Для организации симуляции используется лицензионная игра NBA 2K17.',
//   '2. Матчи проводятся в режиме “Компьютер - Компьютер”.',
//   '3. Настройки игры - стандартные, за исключением:',
//   '3.1. Продолжительность четверти - 12 минут.',
//   '3.2. Сложность All-Star.',
//   '4. В матчах используются актуальные для версии игры сборки команд.',
//   '5. В случае технических проблем с проведением - матч считается прерванным.'
// ];

export const rules = cn('Rules');

class Rules extends Component {
  componentDidMount() {
    this.props.selectSport('rules');
  }

  render(){
    return (
      <div className={rules()}>
        <Title title='Правила'/>
        <Content>
          <Subtitle subtitle='Футбол'/>
          <Items itemsArray={FOOTBALL}/>
          <Subtitle subtitle='Футбол Classic'/>
          <Items itemsArray={FOOTBALL_CLASSIC}/>
          <Subtitle subtitle='Футбол EPL'/>
          <Items itemsArray={FOOTBALL_EPL}/>
          {/* <Subtitle subtitle='Баскетбол'/> */}
          {/* <Items itemsArray={BASKETBALL}/> */}

        </Content>
      </div>
    )
  }
}


const mapDispatchToProps = {
  selectSport,
};

const mapStateToProps = state => ({
  selectedSport: state.selectedSport,
});

export default connect(mapStateToProps, mapDispatchToProps)(Rules);