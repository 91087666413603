export const getTabs = (json) => {
  const result = [];
  if (json && json.league && json.dict && json.dict.league) {
    Object.values(json.league).forEach(item => {
      result.push({
        id: item.id,
        name: json.dict.league[item.id]
      })
    })
  }

  return result;
};

export const getTranslations = json => {
  const result = [];
  if (json && json.games && json.dict && json.dict.cmd) {
    Object.values(json.games).forEach(item => {
      if (item.tv && item.tv.length) {
        result.push({
          id: item.id,
          lid: item.lid,
          tv: item.tv[0],
          score: item.score || '0 : 0',
          date: addCurrentDate(item.date),
          name: `${json.dict.cmd[item.c1_id]} - ${json.dict.cmd[item.c2_id]}`
        });
      }
    })
  }

  return result;
};

export const getResults = json => {
  const result = [];
  if (json && json.games && json.dict && json.dict.cmd) {
    Object.values(json.games).forEach(item => {
      result.push({
        id: item.id,
        date: item.date,
        score: item.score,
        lid: item.lid,
        name: `${json.dict.cmd[item.c1_id]} - ${json.dict.cmd[item.c2_id]}`,
        time: item.time
      })
    })
  }

  return result;
};

export const getFutureEvents = json => {
  const result = [];
  if (json && json.games && json.dict && json.dict.cmd) {
    Object.values(json.games).forEach(item => {
      result.push({
        id: item.id,
        lid: item.lid,
        date: addCurrentDate(item.date),
        name: `${json.dict.cmd[item.c1_id]} - ${json.dict.cmd[item.c2_id]}`
      })
    })
  }

  return result;
};

const addCurrentDate = date => {
  let day = (new Date().getDate()).toString().length > 1 ? (new Date().getDate()).toString() : `0${(new Date().getDate()).toString()}`;
  let month = (new Date().getMonth() + 1).toString().length > 1 ? (new Date().getMonth()+1).toString() : `0${(new Date().getMonth()+1).toString()}`;

  return `${day}/${month} ${date}`;
};