import React, {Component} from 'react';
import Page from '../../Page';
import {connect} from 'react-redux';
import {getSportLive, selectSport, clearState} from "../../../redux/actions/actions";


class SportPage extends Component {

  componentDidMount() {
    this.props.clearState();
    this.props.selectSport('basketball');
  }
  render() {
    const {loading, selectedLeague} = this.props;
    return (
      <Page
        title={'События по баскетболу'}
        loading={loading}
        selectedLeague={selectedLeague}
      />
    )
  }
}


const mapDispatchToProps = {
  getSportLive,
  selectSport,
  clearState
};

const mapStateToProps = state => ({
  loading: state.liveLoading,
  selectedSport: state.selectedSport,
  selectedLeague: state.selectedLeague
});

export default connect(mapStateToProps, mapDispatchToProps)(SportPage);