import React, {Component} from 'react';
import {connect} from 'react-redux'
import {selectTranslation, updateLive, selectLeague} from '../../../redux/actions/actions';
import List from "../../List";

class Live extends Component {
  state = {
    timer: null
  };

  componentDidMount() {
    const {selectedSport, updateLive} = this.props;
    const timerId = setInterval(() => updateLive(selectedSport), 3000);
    this.setState({timer: timerId});
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  render() {
    const {
      translations,
      selectedLeague,
      selectTranslation,
      selectedTranslation,
      tabs,
      selectLeague
    } = this.props;

    let items = translations.filter(item => item.lid === selectedLeague);

    if (items.length === 0 && tabs.length > 0) {
      selectLeague(tabs[0].id);
      items = translations.filter(item => item.lid === tabs[0].id);
    }
    return <List title='Текущие' items={items} live handlerFunction={selectTranslation} selected={selectedTranslation}/>
  }
}


const mapStateToProps = state => ({
  translations: state.translations,
  tabs: state.tabs,
  selectedTranslation: state.selectedTranslation,
  selectedLeague: state.selectedLeague,
  selectedSport: state.selectedSport
});

const mapDispatchToProps = {
  selectTranslation,
  updateLive,
  selectLeague
};

export default connect(mapStateToProps, mapDispatchToProps)(Live);