import React, {Component} from 'react';
import {connect} from 'react-redux'
import {getSportResults} from '../../../redux/actions/actions'
import List from "../../List";

const DEFAULT_LENGTH  = 6;

class Results extends Component {
  state = {
    length: DEFAULT_LENGTH
  };

  showMore = () => this.setState({length: 100});

  hideItems = () => this.setState({length: DEFAULT_LENGTH});

  render(){
    const {results, selectedLeague, loading} = this.props;
    const {length} = this.state;
    const items  = results
      .filter(item => selectedLeague ? item.lid === selectedLeague : true)
      .sort(function (a,b) {return b.time - a.time});

    return(
      <>
        <List
          title='Прошедшие'
          items={items}
          maxLength={length}
          showMore={this.showMore}
          hideItems={this.hideItems}
          loading={loading}
          defaultLength={DEFAULT_LENGTH}
          date
        />
      </>
    )
  }
}

const mapDispatchToProps = {
  getSportResults
};

const mapStateToProps = state => ({
  selectedSport: state.selectedSport,
  results: state.results,
  selectedLeague: state.selectedLeague,
  loading: state.resultsLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);