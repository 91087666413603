import {
  ADD_TABS,
  ADD_TRANSLATIONS,
  GET_FUTURE_EVENTS,
  GET_RESULTS,
  HIDE_LIVE_LOADER,
  SELECT_LEAGUE,
  SELECT_SPORT,
  SHOW_LIVE_LOADER,
  SHOW_RESULTS_LOADER,
  SELECT_TRANSLATION, CLEAR_STATE
} from "../types";

import {
  getFutureEvents,
  getResults,
  getTabs,
  getTranslations
} from "../helpers";


export function clearState() {
  return {type: CLEAR_STATE};
}

export function showLoader(type) {
  return {type}
}

export function hideLoader(type) {
  return {type}
}


export function addTabs(tabs) {
  return {type: ADD_TABS, payload: tabs}
}

export function addTranslations(translations) {
  return {type: ADD_TRANSLATIONS, payload: translations};
}

export function selectLeague(league, translations = []) {

  return async dispatch => {
    dispatch({type: SELECT_LEAGUE, payload: league});
    if (translations.length) {
      const first = translations.filter(item => item.lid === league)[0];
      dispatch(selectTranslation(first))
    }

  }
}


export function selectSport(sport) {
  const today = new Date();
  const [year, month, day] = [today.getFullYear(), today.getMonth() + 1, today.getDate()];
  const date = `${year}-${month}-${day}`;

  return async dispatch => {
    dispatch({type: SELECT_SPORT, payload: sport});
    dispatch(getSportLive(sport));
    dispatch(getSportResults(sport, date));
    dispatch(getSportFutureEvents(sport));
  }
}

export function selectTranslation(item = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.selectedTranslation && state.selectedTranslation.id !== null) {
      if (item.id !== state.selectedTranslation.id) {
        dispatch({type: SELECT_TRANSLATION, payload: {id: item.id, source: item.tv}});
      }
    } else {
      dispatch({type: SELECT_TRANSLATION, payload: {id: item.id, source: item.tv}});
    }
  }

}

export function updateLive(sport) {
  return async dispatch => {
    try {
      const response = await fetch(`/api.php?action=get_live&sport=${sport}`);
      const json = await response.json();
      const tabs = getTabs(json);
      const translations = getTranslations(json);
      dispatch(addTabs(tabs));
      dispatch(addTranslations(translations));
    } catch (e) {
      console.error(e);

    }
  }
}

export function getSportResults(sport, date) {
  return async dispatch => {
    dispatch(showLoader(SHOW_RESULTS_LOADER));
    try {
      const response = await fetch(`/api.php?action=get_results&sport=${sport}&date_from=${date}`);
      const json = await response.json();
      const results = getResults(json.result);
      dispatch({type: GET_RESULTS, payload: results});
    } catch (e) {
      console.error(e);
      dispatch(hideLoader(HIDE_LIVE_LOADER));
    }
  }
}

export function updateResults(sport, date) {
  return async dispatch => {
    try {
      const response = await fetch(`/api.php?action=get_results&sport=${sport}&date_from=${date}`);
      const json = await response.json();
      const results = getResults(json.result);
      dispatch({type: GET_RESULTS, payload: results});
    } catch (e) {
      console.error(e);
    }
  }
}

export function getSportFutureEvents(sport) {
  return async dispatch => {
    dispatch(showLoader(SHOW_RESULTS_LOADER));
    try {
      const response = await fetch(`/api.php?action=get_schedule&sport=${sport}`);
      const json = await response.json();
      const futureEvents = getFutureEvents(json.result);
      dispatch({type: GET_FUTURE_EVENTS, payload: futureEvents});
    } catch (e) {
      console.error(e);
      dispatch(hideLoader(HIDE_LIVE_LOADER));
    }
  }
}


export function getSportLive(sport) {
  return async dispatch => {
    dispatch(showLoader(SHOW_LIVE_LOADER));
    try {
      const response = await fetch(`/api.php?action=get_live&sport=${sport}`);
      const json = await response.json();
      const tabs = getTabs(json);
      const translations = getTranslations(json);
      dispatch(addTabs(tabs));
      dispatch(addTranslations(translations));
      if (tabs.length) {
        dispatch(selectLeague(tabs[0].id, translations));
        // dispatch(selectTranslation((translations[0])))
      }
      dispatch(hideLoader(HIDE_LIVE_LOADER));
    } catch (e) {
      console.error(e);
      dispatch(hideLoader(HIDE_LIVE_LOADER));

    }
  }
}




