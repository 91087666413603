import React from 'react';
import {cn} from "@bem-react/classname";
import {connect} from 'react-redux';
import Background from './-Background';
import Player from './-Player';
import './style.scss';

export const video = cn('Video');

const Video = ({selectedSport}) => {
  return (
    <div className={video()}>
      <Player />
      <Background selectedSport={selectedSport}/>
    </div>
  )
};

const mapStateToProps = state => ({
  selectedSport: state.selectedSport
});

export default connect(mapStateToProps, null)(Video);