import React from 'react';

import {channels} from "../index";
import Logo from '../-Logo';
import Text from '../-Text';
import './style.scss'

export default ({image, text, link}) => (
  <a href={link} target='_blank'  rel='noopener noreferrer' className={channels('Item')}>
    <Logo image={image}/>
    <Text text={text} />
  </a>
)