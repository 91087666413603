import React, {Component} from 'react';
import {connect} from 'react-redux'
import {getSportFutureEvents} from '../../../redux/actions/actions'
import List from "../../List";

const DEFAULT_LENGTH = 8;

class Results extends Component {
  state = {
    length: DEFAULT_LENGTH
  };

  showMore = () => this.setState({length: 100});
  hideItems = () => this.setState({length: DEFAULT_LENGTH});


  render(){
    const {futureEvents, selectedLeague} = this.props;
    const items  = futureEvents.filter(item => selectedLeague ? item.lid === selectedLeague : true);
    return(
      <>
        <List
          title='Будущие'
          items={items}
          maxLength={this.state.length}
          showMore={this.showMore}
          hideItems={this.hideItems}
          defaultLength={DEFAULT_LENGTH}
        />
      </>
    )
  }
}

const mapDispatchToProps = {
  getSportFutureEvents
};

const mapStateToProps = state => ({
  futureEvents: state.futureEvents,
  selectedLeague: state.selectedLeague,
  selectedSport: state.selectedSport,
  loading: state.resultsLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);