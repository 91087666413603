import React from 'react';

import {list} from '../index';
import Date from '../-Date';
import Text from '../-Text';
import Result from '../-Results';
import './style.scss';

export default ({date, text, results, handlerFunction, selected, live}) => (
  <div className={list('Item', {selected})} onClick={live && handlerFunction }>
    <Date date={date}/>
    <div>
      <Text text={text}/>
      <Result results={results} live={live}/>
    </div>
  </div>
);