import React from 'react';
import {list} from '../index';
import './style.scss';

export default ({handlerFunction, hide = false}) => {
  return (
    <div className={list('ShowMore')} onClick={handlerFunction}>
      {!hide ? 'Смотреть все' : 'Cвернуть'}
      {!hide ? <img src="/img/arrow.svg" alt="arrow"/> : <img src="/img/arrow.svg" alt="arrow" style={{transform: 'rotate(180deg)', marginLeft: '10px', marginBottom:'2px'}}/>}
    </div>

  )
}
