export const GET_RESULTS = 'GET_FOOTBALL_RESULTS';
export const GET_LIVE = 'GET_FOOTBALL_LIVE';
export const GET_FUTURE_EVENTS = 'GET_FOOTBALL_FUTURE_EVENTS';
export const SHOW_RESULTS_LOADER = 'SHOW_RESULTS_LOADER';
export const HIDE_RESULTS_LOADER = 'HIDE_RESULTS_LOADER';
export const SHOW_LIVE_LOADER = 'SHOW_LIVE_LOADER';
export const HIDE_LIVE_LOADER = 'HIDE_LIVE_LOADER';
export const ADD_TABS = 'ADD_TABS';
export const ADD_TRANSLATIONS = 'ADD_TRANSLATIONS';
export const SELECT_LEAGUE = 'SELECT_LEAGUE';
export const SELECT_SPORT = 'SELECT_SPORT';
export const SELECT_TRANSLATION = 'SELECT_TRANSLATION';
export const CLEAR_STATE = 'CLEAR_STATE';



