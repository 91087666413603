import React from 'react';
import ReactTwitchEmbedVideo from "react-twitch-embed-video"
import {connect} from 'react-redux';
import {video} from '../index';
import './style.scss';

const Player = ({selectedTranslation}) => {
 const source = selectedTranslation ? selectedTranslation.source.toLowerCase().replace('twitch.tv/','') : selectedTranslation;

 return (
   <div className={video('Player')}>
     {
       selectedTranslation &&
       <ReactTwitchEmbedVideo
         autoplay
         channel={source}
         height="100%"
         layout="video"
         muted={true}
         // targetId="twitch-embed"
         width="100%"
       />
     }

   </div>
 )
};

const mapStateToProps = (state) => ({
  selectedTranslation: state.selectedTranslation
});

export default connect(mapStateToProps, null)(Player);
