import React, {Component} from 'react';
import {cn} from '@bem-react/classname';

import Container from './-Container';
import Burger from './-Burger';
import Icon from './-Icon';
import Item from './-Item';
import RightItem from './-RightItem';
import LoginMob from './-LoginMob';
import LeftMenu from './-LeftMenu';
import RightBlock from './-RightBlock';
import Modal from '../Modal';
import './style.scss';

export const header = cn('Header');

const MENU_ITEMS = [
  {title: 'Футбол', path: '/football', tag: 'football'},
  // {title: 'Хоккей', path: '/hockey', tag: 'hockey'},
  // {title: 'Баскетбол', path: '/basketball', tag: 'basketball'},
];

export default class Header extends Component {
  state = {
    login: false,
    show: false
  };

  login = () => {
    this.setState({login: !this.state.login})
  };

  toggle = () => {
    this.setState({show: !this.state.show})
  };


  render() {
    const {login, show} = this.state;
    const items = MENU_ITEMS.map(item => <Item title={item.title} path={item.path} key={item.title} tag={item.tag}/>);

    return (
      <div className={header()}>
        {show && <LeftMenu items={items} close={this.toggle}/>}
        <Container>
          <Burger open={this.toggle}/>
          <Icon/>
          {items}
          <RightBlock>
            {/*<Item title='Правила' path='/rules' tag='rules'/>*/}
            <RightItem handler={this.login} text='Регистрация'/>
            <RightItem handler={this.login} text='Войти'/>
          </RightBlock>
          <LoginMob login={this.login}/>
          {login ?
            <Modal show={login} close={this.login}>
              <div style={{textAlign:'center', paddingTop: '70px'}}>Данный раздел находится в разработке.</div>
            </Modal>
            : null}
        </Container>
      </div>
  )
  }
}