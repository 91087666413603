import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import Header from './components/Header';
import Container from './components/Container';
import Channels from './components/Channels';
import Football from './routes/football';
import Basketball from './routes/basketball';
import Hockey from './routes/hockey';
import Rules from './routes/rules';

import Footer from './components/Footer';

export default function() {
  return (
    <Router>
        <Header/>
        <Container>
          <Switch>
            <Route path='/football'>
              <Football/>
            </Route>
            <Route path='/basketball'>
              <Basketball/>
            </Route>
            <Route path='/hockey'>
              <Hockey/>
            </Route>
            <Route path='/rules'>
              <Rules/>
            </Route>
            {/*//Default route*/}
            <Route>
              <Redirect to='/football'/>
            </Route>

          </Switch>
        </Container>
        <Channels/>
        <Footer/>
    </Router>
  );
}

